@import url("https://fonts.googleapis.com/css2?family=Inter&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: transparent;
  color: var(--fg-main);
}

html,
body {
  overflow-x: hidden;
  background-color: var(--bg-main);
}

body {
  margin: 0;
  font-family: "Inter", sans-serif;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
      "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
      sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

:root {
  --accent-main: hsl(272deg, 72%, 47%);
  --accent-lighter: hsl(271deg, 79%, 56%);

  --bg-main: #dcdcdc;
  --bg-secondary: #ffffff;

  --fg-main: #1e293b;

  --shadow-color: rgba(100, 100, 110, 0.2);
}
