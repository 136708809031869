.cardContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 0px groove rgba(0, 0, 0, 0.18);
  border-radius: 0.25em;
  padding: 1.5rem;
  -webkit-box-shadow: 2px 2px 2px 0px rgba(121, 58, 255, 0.38);
  box-shadow: 2px 2px 2px 0px var(--shadow-color);
  width: 100%;
  background-color: var(--bg-secondary);
  transition: background-color 0.5s;
}

a:hover{
  text-decoration: none;
}

.cardContainer:hover {
  transform: scale(1.02);
  transition: 0.1s all ease-in-out;
  background-color: whitesmoke;
}

.cardText {
  font-weight: 5000;
  font-size: large;
  float: right;
}

@media (max-width: 700px) {
  .cardOuter {
    width: 94%;
  }
}

.cardDesc {
  font-size: 0.8em;
  opacity: 0.7;
}
