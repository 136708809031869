.title {
  width: 100%;
  display: block;
  text-align: center;
  color: var(--accent-main);
  padding: 2rem;
}

@media (max-width: 700px) {
  .title {
    pading: 1rem;
  }
}
