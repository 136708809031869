.searchBar {
  margin-bottom: 1em;
  padding: 0.5em;
  width: 32em;
  border-radius: 0.3rem;
  border: 1px solid transparent;
  font-size: large;
  box-shadow: 1px 1px 4px 0px var(--shadow-color);
  background: url(assets/magnifying-glass-svgrepo-com.svg) no-repeat scroll;
  background-size: 1em;
  background-color: var(--bg-secondary);
  background-position: 0.75em center;
  padding-left: 2.3em;
  margin-top: 3em;
  margin-bottom: 1em;
}

.searchBar:focus {
  border: 1px solid var(--accent-lighter);
}
