.buildingCard {
  width: 100%;
  height: 100%;
  min-height: 120px;
  background-color: var(--bg-secondary);
  border-radius: 0.25em;
  box-shadow: var(--shadow-color) 0px 4px 8px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--fg-main);
  text-decoration: none;
  font-weight: bold;
  padding: 1em;
  text-align: center;
}

.buildingCard:hover {
  transform: scale(1.02);
  background-color: whitesmoke;
  box-shadow: rgba(100, 100, 110, 0.4) 0px 4px 12px 0px;
  transition: 0.1s all ease-in-out;
  text-decoration: none;
}

@media (max-width: 700px) {
  .buildingCard {
    min-height: 80px;
  }
}
