.buildingContainer {
  display: flex;
  max-width: 100%;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.buildingContainerGrid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  place-items: center;
  column-gap: 1.5em;
  row-gap: 1em;
  width: 100%;
}

.searchContainer {
  width: 100%;
  display: flex;
  justify-content: center;
}

.h1 {
  width: 100%;
  display: block;
  margin-top: 4em;
}

@media (max-width: 700px) {
  .buildingContainerGrid {
    grid-template-columns: 1fr;
    column-gap: 0;
    padding: 0 5%;
  }

  .searchContainer {
    padding: 1% 5%;
  }
}
