.innerContainer {
  max-width: 70%;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  padding-bottom: 4em;
}

.innerContainerNBP {
  max-width: 70%;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.outerContainer {
  display: flex;
  height: max-content;
  height: 100%;
  width: 100%;
  max-width: 100%;
  justify-content: center;
}

@media (max-width: 700px) {
  .innerContainer {
    max-width: 100%;
  }

  .outerContainer {
    margin: 0px;
    padding: 0px;
  }
}
