.dropdownContainer {
  width: 100%;
  padding-left: 5%;
  display: flex;
  justify-content: left;
}

input {
  margin: 0 0.5em;
  width: max-content;
}

.ClassroomCardsContainer {
  display: grid;
  margin-top: 1em;
  grid-template-columns: 1fr 1fr;
  column-gap: 1.5em;
  row-gap: 1em;
  width: 100%;
  padding: 1% 5%;
  box-sizing: border-box;
}

@media (max-width: 700px) {
  .ClassroomCardsContainer {
    grid-template-columns: 1fr;
    column-gap: 0;
  }

  .dropdownContainer {
    /* flex-direction: column; */
    justify-content: space-between;
    width: 100%;
  }
}
