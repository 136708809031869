.nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--accent-main);
  color: var(--bg-main);
  position: sticky;
  top: 0;
  width: 100%;
  padding: 1em 1.5em;
  z-index: 50;
  border-bottom: 0.8rem solid var(--accent-lighter);
}

.nav a:hover, 
.title {
  text-decoration: none;
  transform: scale(1.02);
}

.nav a,
.title {
  color: var(--bg-secondary);
  font-size: large;
}

.title::after {
  content: "Study Space Finder";
}

@media (max-width: 700px) {
  .title::after {
    content: "SSF";
  }

  .nav {
    padding: 1em;
  }
}
