/* 
DayPilot Calendar Theme 
https://themes.daypilot.org/calendar/theme/crdyv5
Theme Designer Version: 2021.09.25.67272
*/
.legend
{
	display: flex;
	float: left;
	border: 1px solid black;
	margin:auto;
	margin-top: 20px;
	justify-content: center;
	padding: 10px;
}
.unavailableColour
{
	height: 50px;
	width: 50px;
	background-color: #a6a6a6;
	margin: 10px;
	display: inline;
	float: left;
	
}
.calendarContainer {
	padding-left: 6px;
	padding-right: 8px;
}

.calendar_main 
{
	border: 1px solid #7e22ce;
	border-radius: 3px;
}
.calendar_main, .calendar_main td 
{
	font-family: -apple-system,system-ui,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,sans-serif;
	font-size: 13px;
}
.calendar_event { 
	color: #838282;
	font-size: smaller;
	text-align: center;
	-moz-border-radius: 0px;
	-webkit-border-radius: 0px;
	border-radius: 0px;
}
.calendar_event:hover, .calendar_event:active { 
	color: #333333;
	-moz-border-radius: 5px;
	-webkit-border-radius: 5px;
	border-radius: 5px;
}

.calendar_event_inner { 
	position: absolute;
	/* overflow: hidden; */
	left: 0px;
	right: 0px;
	top: 0px;
	bottom: 0px;
	margin: 0px;
	background-color: #a6a6a6;
	opacity: 100%;
	-moz-border-radius: 0px;
	-webkit-border-radius: 0px;
	border-radius: 0px;
	cursor: default;
	box-shadow: #333333;
}
.calendar_event_inner:hover { 
	position: absolute;
	overflow: hidden;
	left: 0px;
	right: 0px;
	top: 0px;
	bottom: 0px;
	margin: 0px;
	background-color: #d1d1d1;
	-moz-border-radius: 0px;
	-webkit-border-radius: 0px;
	border-radius: 0px;
}
.calendar_alldayevent {	
}
.calendar_alldayevent_inner { 
	position: absolute;
	overflow: hidden;
	left: 1px;
	right: 0px;
	top: 1px;
	bottom: 0px;
	margin: 0px;
	color: #333333;
	background-color: #ffffff;
	background: -webkit-gradient(linear, left top, left bottom, from(#ffffff), to(#fafafa));  
	background: -webkit-linear-gradient(top, #ffffff 0%, #fafafa);
	background: -moz-linear-gradient(top, #ffffff 0%, #fafafa);
	background: -ms-linear-gradient(top, #ffffff 0%, #fafafa);
	background: -o-linear-gradient(top, #ffffff 0%, #fafafa);
	background: linear-gradient(top, #ffffff 0%, #fafafa);
	filter: progid:DXImageTransform.Microsoft.Gradient(startColorStr="#ffffff", endColorStr="#fafafa");
	padding: 2px;
	border: 1px solid #cccccc;
	text-align: left;
}
.calendar_alldayheader_inner
{
	text-align: center; 
	position: absolute;
	top: 0px;
	left: 0px;
	bottom: 0px;
	right: 0px;
	border-right: 1px solid #7e22ce;
	border-bottom: 1px solid  #7e22ce;
	color: #333333;
	background: #a755f3;
	background: -webkit-gradient(linear, left top, left bottom, from(#c87dff), to(#bc5fff));
	background: -webkit-linear-gradient(top, #c87dff 0%, #bc5fff);
	background: -moz-linear-gradient(top, #c87dff 0%, #bc5fff);
	background: -ms-linear-gradient(top, #c87dff 0%, #bc5fff);
	background: -o-linear-gradient(top, #c87dff 0%, #bc5fff);
	background: linear-gradient(top, #c87dff 0%, #bc5fff);
	filter: progid:DXImageTransform.Microsoft.Gradient(startColorStr="#c87dff", endColorStr="#bc5fff");
}
.calendar_colheader_inner
{
	text-align: center; 
	padding: 2px;
	position: absolute;
	top: 0px;
	left: 0px;
	bottom: 0px;
	right: 0px;
	border-right: 1px solid #7e22ce;
	border-bottom: 1px solid #7e22ce;
	color: #333333;
	background: #a755f3;
	background: -webkit-gradient(linear, left top, left bottom, from(#c87dff), to(#bc5fff));
	background: -webkit-linear-gradient(top, #c87dff 0%, #bc5fff);
	background: -moz-linear-gradient(top, #c87dff 0%, #bc5fff);
	background: -ms-linear-gradient(top, #c87dff 0%, #bc5fff);
	background: -o-linear-gradient(top, #c87dff 0%, #bc5fff);
	background: linear-gradient(top, #c87dff 0%, #bc5fff);
	filter: progid:DXImageTransform.Microsoft.Gradient(startColorStr="#c87dff", endColorStr="#bc5fff");
}
.calendar_rowheader_inner
{
	font-size: 16pt;
	text-align: right; 
	position: absolute;
	top: 0px;
	left: 0px;
	bottom: 0px;
	right: 0px;
	border-right: 1px solid #7e22ce;
	border-bottom: 1px solid  #7e22ce;
	color: #333333;
	background: #a755f3;
	background: -webkit-gradient(linear, left top, right top, from(#c87dff), to(#bc5fff));
	background: -webkit-linear-gradient(left, #c87dff 0%, #bc5fff);
	background: -moz-linear-gradient(left, #c87dff 0%, #bc5fff);
	background: -ms-linear-gradient(left, #c87dff 0%, #bc5fff);
	background: -o-linear-gradient(left, #c87dff 0%, #bc5fff);
	background: linear-gradient(left, #c87dff 0%, #bc5fff);
	filter: progid:DXImageTransform.Microsoft.Gradient(startColorStr="#c87dff", endColorStr="#bc5fff", GradientType=1);
}
.calendar_rowheader_minutes 
{
	font-size:10px; 
	vertical-align: super; 
	padding-left: 2px;
	padding-right: 2px;
}
.calendar_corner_inner
{
	position: absolute;
	top: 0px;
	left: 0px;
	bottom: 0px;
	right: 0px;
	border-right: 1px solid #7e22ce;
	border-bottom: 1px solid  #7e22ce;
	color: #333333;
	background: #a755f3;
	background: -webkit-gradient(linear, left top, right top, from(#c87dff), to(#bc5fff));
	background: -webkit-linear-gradient(left, #c87dff 0%, #bc5fff);
	background: -moz-linear-gradient(left, #c87dff 0%, #bc5fff);
	background: -ms-linear-gradient(left, #c87dff 0%, #bc5fff);
	background: -o-linear-gradient(left, #c87dff 0%, #bc5fff);
	background: linear-gradient(left, #c87dff 0%, #bc5fff);
	filter: progid:DXImageTransform.Microsoft.Gradient(startColorStr="#c87dff", endColorStr="#bc5fff", GradientType=1);
}
.calendar_cornerright_inner
{
	position: absolute;
	top: 0px;
	left: 0px;
	bottom: 0px;
	right: 0px;
	border-right: 1px solid #7e22ce;
	border-bottom: 1px solid  #7e22ce;
	color: #333333;
	background: #a755f3;
	background: -webkit-gradient(linear, left top, left bottom, from(#c87dff), to(#bc5fff));
	background: -webkit-linear-gradient(top, #c87dff 0%, #bc5fff);
	background: -moz-linear-gradient(top, #c87dff 0%, #bc5fff);
	background: -ms-linear-gradient(top, #c87dff 0%, #bc5fff);
	background: -o-linear-gradient(top, #c87dff 0%, #bc5fff);
	background: linear-gradient(top, #c87dff 0%, #bc5fff);
	filter: progid:DXImageTransform.Microsoft.Gradient(startColorStr="#c87dff", endColorStr="#bc5fff");
}
.calendar_rowheader_inner {
	padding: 2px;
}
.calendar_cell_inner
{
	position: absolute;
	top: 0px;
	left: 0px;
	bottom: 0px;
	right: 0px;
	border-right: 1px solid #999999;
	border-bottom: 1px solid #999999;
	background: #f5f5f5;
}
.calendar_cell_business .calendar_cell_inner {
	background: #f5f5f5;
}
.calendar_message 
{
	padding: 10px;
	opacity: 0.9;
	filter: alpha(opacity=90);
	color: #ffffff;
	background: #ffa216;
}
.calendar_shadow_inner 
{
	background-color: #666666;
	opacity: 0.5;
	filter: alpha(opacity=50);
	height: 100%;
	-moz-border-radius: 5px;
	-webkit-border-radius: 5px;
	border-radius: 5px;
}
.calendar_event_delete {
	background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAsAAAALCAYAAACprHcmAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAAadEVYdFNvZnR3YXJlAFBhaW50Lk5FVCB2My41LjExR/NCNwAAAI5JREFUKFNtkLERgCAMRbmzdK8s4gAUlhYOYEHJEJYOYOEwDmGBPxC4kOPfvePy84MGR0RJ2N1A8H3N6DATwSQ57m2ql8NBG+AEM7D+UW+wjdfUPgerYNgB5gOLRHqhcasg84C2QxPMtrUhSqQIhg7ypy9VM2EUZPI/4rQ7rGxqo9sadTegw+UdjeDLAKUfhbaQUVPIfJYAAAAASUVORK5CYII=) center center no-repeat; 
	opacity: 0.6; 
	-ms-filter:'progid:DXImageTransform.Microsoft.Alpha(Opacity=60)'; 
	cursor: pointer;
}
.calendar_event_delete:hover {
	opacity: 1;
	-ms-filter: none;
}
.calendar_scroll_up {
	background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAB3RJTUUH2wESDiYcrhwCiQAAAAlwSFlzAAAuIwAALiMBeKU/dgAAAARnQU1BAACxjwv8YQUAAACcSURBVHjaY2AgF9wWsTW6yGMlhi7OhC7AyMDQzMnBXIpFHAFuCtuaMTP+P8nA8P/b1x//FfW/HHuF1UQmxv+NUP1c3OxMVVhNvCVi683E8H8LXOY/w9+fTH81tF8fv4NiIpBRj+YoZtZ/LDUoJmKYhsVUpv0MDiyMDP96sIYV0FS2/8z9ICaLlOhvS4b/jC//MzC8xBG0vJeF7GQBlK0xdiUzCtsAAAAASUVORK5CYII=);
}
.calendar_scroll_down {
	background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAALiMAAC4jAXilP3YAAACqSURBVChTY7wpam3L9J+xmQEP+PGPKZZxP4MDi4zI78uMDIwa2NT+Z2DYovrmiC+TI8OBP/8ZmEqwGvif4e8vxr+FIDkmEKH25vBWBgbG0+iK/zEwLtF+ffwOXCGI8Y+BoRFFIdC030x/WmBiYBNhpgLdswNJ8RSYaSgmgk39z1gPUfj/29ef/9rwhQTDHRHbrbdEbLvRFcGthkkAra/9/uMvhkK8piNLAgCRpTnNn4AEmAAAAABJRU5ErkJggg==);
}
.calendar_now { background-color: red; }
.calendar_now:before { content: ''; top: -5px; border-width: 5px; border-color: transparent transparent transparent red; border-style: solid; width: 0px; height:0px; position: absolute; -moz-transform: scale(.9999); }
.calendar_shadow_forbidden .calendar_shadow_inner { background-color: red; }
.calendar_shadow_top { box-sizing: border-box; padding:2px;border:1px solid #ccc;background:#fff;background: -webkit-gradient(linear, left top, left bottom, from(#ffffff), to(#eeeeee));background: -webkit-linear-gradient(top, #ffffff 0%, #eeeeee);background: -moz-linear-gradient(top, #ffffff 0%, #eeeeee);background: -ms-linear-gradient(top, #ffffff 0%, #eeeeee);background: -o-linear-gradient(top, #ffffff 0%, #eeeeee);background: linear-gradient(top, #ffffff 0%, #eeeeee);filter: progid:DXImageTransform.Microsoft.Gradient(startColorStr="#ffffff", endColorStr="#eeeeee"); }
.calendar_shadow_bottom { box-sizing: border-box; padding:2px;border:1px solid #ccc;background:#fff;background: -webkit-gradient(linear, left top, left bottom, from(#ffffff), to(#eeeeee));background: -webkit-linear-gradient(top, #ffffff 0%, #eeeeee);background: -moz-linear-gradient(top, #ffffff 0%, #eeeeee);background: -ms-linear-gradient(top, #ffffff 0%, #eeeeee);background: -o-linear-gradient(top, #ffffff 0%, #eeeeee);background: linear-gradient(top, #ffffff 0%, #eeeeee);filter: progid:DXImageTransform.Microsoft.Gradient(startColorStr="#ffffff", endColorStr="#eeeeee"); }
.calendar_crosshair_vertical, .calendar_crosshair_horizontal, .calendar_crosshair_left, .calendar_crosshair_top { background-color: gray; opacity: 0.2; filter: alpha(opacity=20); }
.calendar_loading { background-color: orange; color: white; padding: 2px; }
.calendar_scroll { background-color: #f3f3f3; }
